































import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import PivotTable from "@/components/UIComponents/PivotTable.vue";
import { CropStore, LoadStore, ReportStore, JWTStore } from "@/store/modules";
import Cropstat from "@/components/UIComponents/Cropstat.vue";
import Recentvarieties from "@/components/UIComponents/Recentvarieties.vue";
import aboveFooter from "@/components/UIComponents/aboveFooter.vue";

@Component({
  components: {
    Button,
    PivotTable,
    Cropstat,
    Recentvarieties,
    aboveFooter
  }
})
export default class Home extends Vue {
  rows = ["Seed Class", "Crop", "Variety"];
  cols = ["Province"];
  vals = ["Demand", "Supply", "Allocation"];
  hiddenFromDragDrop = ["Supply", "Demand", "Allocation"];
  loading: boolean = false;
  role: string = "";

  get pivotData() {
    return ReportStore.AdHocReportList;
  }

  async created() {
    this.role = JWTStore.role;
    this.loading = true;
    await ReportStore.getAdHocReport(this.role);
    this.loading = false;
  }
}
