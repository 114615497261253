







































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DemandSupplyStore } from "../../store/modules";

@Component
export default class Cropstat extends Vue {
  selected: string = "Breeder";

  isHidden(key: string) {
    let isHidden: boolean = true;
    if (key == this.selected) {
      isHidden = false;
    }

    return isHidden;
  }

  get supplierStat() {
    return DemandSupplyStore.SupplierStat;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  getSeedTypeId(key: string) {
    let id: number = 0;
    let seed = this.seedTypes.filter(x => x.name.toLowerCase() == String(key).toLowerCase())[0];
    if (seed) {
      return seed.id;
    }
    return id;
  }

  created() {
    DemandSupplyStore.getAllSeedType();
    DemandSupplyStore.getSupplierStat();
  }
}
